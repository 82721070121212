<template>
  <div>
    <!--Progress Bar-->
    <v-dialog
      v-model="progress"
      max-width="290"
      persistent
      hide-overlay
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-center">
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container
      v-if="!progress"
      fluid
      fill-height
      grid-list-lg
    >
      <v-layout
        row
        wrap
      >
        <!---->
        <v-flex xs12>
          <v-card
            flat
            color="accent"
          >
            <v-card-text class="pa-0">
              <v-container
                fluid
                grid-list-md
                class="pt-0 px-0"
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-toolbar
                      flat
                      dense
                      dark
                      color="secondary"
                    >
                      <v-icon>fal fa-tag</v-icon>
                      <v-toolbar-title class="pl-2">
                        Price Lists
                      </v-toolbar-title>
                    </v-toolbar>

                    <!-- Price Lists -->
                    <v-list
                      v-if="priceListsStore.priceLists.length > 0"
                      two-line
                      class="pt-0"
                    >
                      <template v-for="(item, i) in priceListsStore.priceLists">
                        <v-list-item
                          :key="i"
                          @click="goPriceList(item.d_path)"
                        >
                          <v-list-item-content>

                            <v-list-item-title class="font-weight-bold">
                              {{item.d_name}}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>

                    <v-list v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="red--text subtitle-1 text-xs-center">
                            No Price Lists Exist
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'PriceLists',
  mixins: [mixin],
  computed: {
    ...mapState({
	    usersStore: (state) => state.usersStore,
      priceListsStore: (state) => state.priceListsStore,
    }),
  },
  data() {
    return {
    	progress: false,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
	    this.progress = true;

	    const user = this.$store.getters['usersStore/getAuthUser'];

	    if (!user) {
		    this.$router.push({ name: 'logout' });
	    } else {
		    await this.$store.dispatch('priceListsStore/fetch');
		    this.progress = false;
	    }
    },

	  goPriceList(path) {
		  window.open(path, '_blank');
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor: pointer;
  }
  button {
    outline: none;
  }
</style>

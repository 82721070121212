<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <!---->
        <v-flex xs12>
          <v-card flat color="accent">
            <v-card-text class="pa-0">
              <v-container fluid grid-list-md class="pt-0 px-0">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-toolbar flat dense dark color="secondary">
                      <v-icon>fal fa-tag</v-icon>
                      <v-toolbar-title class="pl-2">
                        Invoices
                      </v-toolbar-title>
                    </v-toolbar>

                    <!-- Search & Pagination Toolbar -->
                    <v-toolbar flat color="white" class="pt-0">
                      <!--  Filter Icon -->
                      <v-menu
                        v-model="invoiceFilter"
                        offset-y
                        right
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn rounded color="primary" v-on="on">
                            <v-icon small left>
                              fal fa-filter </v-icon
                            ><span
                              v-if="
                                invoicesStore.filters.id ||
                                  invoicesStore.filters.customerID ||
                                  invoicesStore.filters.orderId ||
                                  invoicesStore.filters.date ||
                                  invoicesStore.filters.po ||
                                  invoicesStore.filters.jobName ||
                                  invoicesStore.filters.status
                              "
                              >Filtered</span
                            ><span v-else>Filter</span>
                          </v-btn>
                        </template>

                        <v-card max-width="400px">
                          <v-card-text>
                            <v-container fluid grid-list-sm>
                              <v-layout row wrap>
                                <v-flex
                                  v-if="
                                    invoicesStore.filters.id ||
                                      invoicesStore.filters.customerID ||
                                      invoicesStore.filters.orderId ||
                                      invoicesStore.filters.date ||
                                      invoicesStore.filters.po ||
                                      invoicesStore.filters.jobName ||
                                      invoicesStore.filters.status
                                  "
                                  xs12
                                >
                                  <v-btn
                                    block
                                    rounded
                                    color="primary"
                                    @click="clearFilter()"
                                  >
                                    <v-icon small left>
                                      fal fa-times </v-icon
                                    >Clear Filters
                                  </v-btn>
                                </v-flex>

                                <v-flex
                                  xs12
                                  v-if="
                                    usersStore.authUser.customers.length > 0
                                  "
                                >
                                  <v-autocomplete
                                    v-model="filterBar.customerID"
                                    :items="usersStore.authUser.customers_view"
                                    item-text="d_companyName"
                                    item-value="id"
                                    label="Customer"
                                    placeholder=" "
                                    persistent-placeholder
                                    clearable
                                    clear-icon="fal fa-times-circle"
                                  />
                                </v-flex>

                                <v-flex xs12>
                                  <v-text-field
                                    v-model="filterBar.id"
                                    label="Invoice ID"
                                    placeholder=" "
                                    persistent-placeholder
                                  />
                                </v-flex>
                                <v-flex xs12>
                                  <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    box
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="filterBar.date"
                                        label="Date"
                                        placeholder=" "
                                        persistent-placeholder
                                        append-icon="fal fa-calendar-alt"
                                        v-on="on"
                                        @blur="
                                          date1 = parseDate(filterBar.date)
                                        "
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="date1"
                                      color="primary"
                                      @input="menu1 = false"
                                    />
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12>
                                  <v-text-field
                                    v-model="filterBar.orderId"
                                    label="Order ID"
                                    placeholder=" "
                                    persistent-placeholder
                                  />
                                </v-flex>
                                <v-flex xs12>
                                  <v-text-field
                                    v-model="filterBar.po"
                                    label="Purchase Order"
                                    placeholder=" "
                                    persistent-placeholder
                                  />
                                </v-flex>
                                <v-flex xs12>
                                  <v-text-field
                                    v-model="filterBar.jobName"
                                    label="Job Name"
                                    placeholder=" "
                                    persistent-placeholder
                                  />
                                </v-flex>
                                <v-flex xs12>
                                  <v-autocomplete
                                    v-model="filterBar.status"
                                    :items="invoiceStatus"
                                    item-text="name"
                                    item-value="id"
                                    label="Status"
                                    placeholder=" "
                                    persistent-placeholder
                                  />
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              rounded
                              block
                              color="primary"
                              @click="filter()"
                            >
                              <v-icon small left>
                                fal fa-check </v-icon
                              >Submit
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-toolbar>

                    <!-- Pagination Toolbar -->
                    <pagination
                      :show="
                        invoicesStore.invoices.data &&
                        invoicesStore.invoices.data.length > 0 &&
                        invoicesStore.invoices.last_page > 1
                          ? true
                          : false
                      "
                      :current-page="invoicesStore.invoices.current_page"
                      :last-page="invoicesStore.invoices.last_page"
                      @paginate-event="filter($event)"
                    />

                    <!-- Invoices List -->
                    <v-list
                      v-if="invoicesStore.invoices.data.length > 0"
                      two-line
                      class="pt-0"
                    >
                      <template
                        v-for="(item, i) in invoicesStore.invoices.data"
                      >
                        <v-list-item :key="i" @click="goInvoice(item.uuid)">
                          <v-list-item-content>
                            <v-list-item-title
                              v-if="usersStore.authUser.customers.length > 0"
                              class="font-weight-bold"
                            >
                              {{ item.customer.d_companyName }}
                            </v-list-item-title>

                            <v-list-item-title>
                              {{ item.d_invoiceNumber }} -
                              {{ formatDate(item.d_date) }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              ${{ item.d_amountTotal }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              PO #{{ item.order.d_customerPurchaseOrder }} - Job
                              name {{ item.order.d_jobName }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text
                              :class="
                                'text-xs-right subtitle-1 ' +
                                  [
                                    item.f_status === 0
                                      ? 'green--text lighten-1--text'
                                      : 'primary--text'
                                  ]
                              "
                            >
                              {{ translateInvoiceStatus(item.f_status) }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>

                    <v-list v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="red--text subtitle-1 text-xs-center"
                          >
                            No Invoices Exist
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import Pagination from "@/components/Pagination";

export default {
  name: "Invoices",
  components: {
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      invoicesStore: state => state.invoicesStore
    })
  },
  data() {
    return {
      progress: false,
      invoiceFilter: false,
      menu1: "",
      date1: "",
      filterBar: {
        id: "",
        customerID: "",
        orderId: "",
        date: "",
        po: "",
        jobName: "",
        status: "",
        page: ""
      },
      invoiceStatus: [
        {
          id: 0,
          name: "Paid"
        },
        {
          id: 1,
          name: "Open"
        },
        {
          id: 2,
          name: "Canceled"
        }
      ]
    };
  },

  watch: {
    date1() {
      this.filterBar.date = this.formatDate(this.date1);
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.progress = true;

      const user = this.$store.getters["usersStore/getAuthUser"];

      const filters = this.$store.getters["invoicesStore/getFilters"];

      this.filterBar.id = filters.id;
      this.filterBar.customerID = filters.customerID;
      this.filterBar.date1 = filters.date;
      this.filterBar.orderId = filters.orderId;
      this.filterBar.po = filters.po;
      this.filterBar.jobName = filters.jobName;
      this.filterBar.status = filters.status;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: filters.id,
        customerID: filters.customerID,
        date: filters.date,
        orderId: filters.orderId,
        po: filters.po,
        jobName: filters.jobName,
        status: filters.status,
        page: filters.page
      };

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        await this.$store.dispatch("invoicesStore/fetchByCustomer", data);
        this.progress = false;
      }
    },

    async filter(page) {
      this.invoiceFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: this.filterBar.id,
        customerID: this.filterBar.customerID,
        date: this.filterBar.date,
        orderId: this.filterBar.orderId,
        po: this.filterBar.po,
        jobName: this.filterBar.jobName,
        status: this.filterBar.status,
        page
      };

      this.$store.dispatch("invoicesStore/storeFilters", data);
      await this.$store.dispatch("invoicesStore/fetchByCustomer", data);
    },

    async clearFilter() {
      this.invoiceFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      this.filterBar.id = "";
      this.filterBar.customerID = "";
      this.filterBar.date = "";
      this.filterBar.orderId = "";
      this.filterBar.po = "";
      this.filterBar.jobName = "";
      this.filterBar.status = "";
      this.filterBar.page = 1;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: this.filterBar.id,
        customerID: this.filterBar.customerID,
        date: this.filterBar.date,
        orderId: this.filterBar.orderId,
        po: this.filterBar.po,
        jobName: this.filterBar.jobName,
        status: this.filterBar.status,
        page: this.filterBar.page
      };
      this.$store.dispatch("invoicesStore/storeFilters", data);
      await this.$store.dispatch("invoicesStore/fetchByCustomer", data);
    },

    goInvoice(uuid) {
      this.$router.push({
        name: "invoiceDetail",
        params: { invoiceUUID: uuid }
      });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
